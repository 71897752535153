import React from 'react';
import CommercialForm from './CommercialForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const COMMERCIAL_FORM_KEY = loadStripe(process.env.REACT_APP_STRIPE_KEY_COMMERCIAL);

const CommercialFormStripe = () => {
    return (
        <Elements stripe={COMMERCIAL_FORM_KEY}>
            <CommercialForm />
        </Elements>
    );
}

export default CommercialFormStripe;
