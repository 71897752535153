import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropanePermitForm from './PropanePermitForm';
const PROPANE_FORM_KEY = loadStripe(process.env.REACT_APP_STRIPE_PROPANE_FORM_KEY);

const PropanePermitFormStripe = () => {
    return (
        <Elements stripe={PROPANE_FORM_KEY}>
            <PropanePermitForm/>
        </Elements>
    );
}

export default PropanePermitFormStripe;
