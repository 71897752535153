import React from 'react';
import './Resources.css';

function Resources() {
  return (
    <section id="Resources">
      <h2>South Summit Fire Protection District</h2>
      <p>Station 41, Station 42, Station 43</p>
      {/* Add more content about your department */}
    </section>
  );
}

export default Resources;
