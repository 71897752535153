import React from 'react';
import './Recruitment & Hiring.css';

function RecruitmentHiring() {
  return (
    <section id="RecruitmentHiring">
      <h2>South Summit Fire Protection District</h2>
      <p>This page is currently under maintanence. We are working as quickly as we can to bring you a much more rich experience</p>
      {/* Add more content about your department */}
    </section>
  );
}

export default RecruitmentHiring;
