import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SprinklerForm from './SprinklerForm';
const SPRINKLER_FORM_KEY = loadStripe(process.env.REACT_APP_STRIPE_SPRINKLER_FORM_KEY);

const SprinklerFormStripe = () => {
    return (
        <Elements stripe={SPRINKLER_FORM_KEY}>
            <SprinklerForm />
        </Elements>
    );
}

export default SprinklerFormStripe;
