import React from 'react';
import ClearanceForm from './ClearanceForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const RESIDENTIAL_FORM_KEY = loadStripe(process.env.REACT_APP_STRIPE_KEY_RESIDENTIAL);

const ClearanceFormStripe = () => {
    return (
        <Elements stripe={RESIDENTIAL_FORM_KEY}>
            <ClearanceForm />
        </Elements>
    );
}

export default ClearanceFormStripe;
