import React, { useState } from "react";
import "./Permits.css";
import ClearanceFormStripe from "./ClearanceFormStripe";
import SprinklerFormStripe from "./SprinklerFormStripe";
import PropanePermitFormStripe from "./PropanePermitFormStripe";
import CommercialFormStripe from "./CommercialFormStripe";

function Permits() {
  const [currentForm, setCurrentForm] = useState("clearance");

  const renderForm = () => {
    switch (currentForm) {
      case "clearance":
        return <ClearanceFormStripe />;
      case "commercial":
        return <CommercialFormStripe />;
      case "propane":
        return <PropanePermitFormStripe />;
      case "sprinkler":
        return <SprinklerFormStripe />;
      default:
        return <ClearanceFormStripe />;
    }
  };

  return (
    <section id="Permits" className="col-md-8">
      <div
        className="header-section"
        style={{ position: "relative", textAlign: "center" }}
      >
        <div
          className="download-section"
          style={{ position: "absolute", left: 0 }}
        >
          <h3 style={{ fontSize: "15px" }}>Downloadable Clearance Form</h3>
          <a href="/Clearance-Form.pdf" download>
            <button className="btn btn-danger">Download Form</button>
          </a>
        </div>
        <h2>South Summit Fire Protection District</h2>
      </div>
      <div
        className="btn-group "
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <input
          type="radio"
          className="btn-check btn-color-label"
          name="btnradio"
          id="btnradio1"
          autoComplete="off"
          defaultChecked
        />
        <label
          className="btn btn-outline-danger btn-color-label "
          onClick={() => setCurrentForm("clearance")}
          htmlFor="btnradio1"
        >
          Residential Form
        </label>

        <input
          type="radio"
          className="btn-check btn-color-label"
          name="btnradio"
          id="btnradio4"
          autoComplete="off"
        />
        <label
          className="btn btn-outline-danger btn-color-label "
          onClick={() => setCurrentForm("commercial")}
          htmlFor="btnradio4"
        >
          Commercial Form
        </label>

        <input
          type="radio"
          className="btn-check btn-color-label"
          name="btnradio"
          id="btnradio2"
          autoComplete="off"
        />
        <label
          className="btn btn-outline-danger btn-color-label"
          onClick={() => setCurrentForm("propane")}
          htmlFor="btnradio2"
        >
          Propane Permit Form
        </label>

        <input
          type="radio"
          className="btn-check btn-color-label"
          name="btnradio"
          id="btnradio3"
          autoComplete="off"
        />
        <label
          className="btn btn-outline-danger btn-color-label"
          onClick={() => setCurrentForm("sprinkler")}
          htmlFor="btnradio3"
        >
          Sprinkler Form
        </label>
      </div>
      {renderForm()}
      <p style={{ marginTop: "20px" }}>
        Please note: A 3.5% processing fee is added to the total for each
        payment.
      </p>
    </section>
  );
}

export default Permits;
