import React from "react";
import "./Public Records.css";

function PublicRecords() {
  return (
    <div>
      <section id="PublicRecords">
        <h2>Agenda and Meeting Minutes</h2>
        <p></p>
      </section>
      {/* Temp Section for PDFs */}
      <section id="PublicRecords">
        <a
          href="/8 AUGUST 2024 REGULAR MEETING.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="download-link" // (Optional) For styling
        >
          Business Meeting Agenda August 21, 2024
        </a>
      </section>
      <section id="PublicRecords">
        <a
          href="/8 AUGUST 2024.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="download-link" // (Optional) For styling
        >
          August 21, 2024
        </a>
      </section>
      <section id="PublicRecords">
        <a
          href="/9 SEPTEMBER 2024 Work Meeting.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="download-link" // (Optional) For styling
        >
          SEPTEMBER 17, 2024 Work Meeting, 2024
        </a>
      </section>
      <section id="PublicRecords">
        <a
          href="/9 SEPTEMBER 2024 REGULAR MEETING.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="download-link" // (Optional) For styling
        >
          SEPTEMBER 17, 2024 REGULAR MEETING, 2024
        </a>
      </section>
    </div>
  );
}

export default PublicRecords;
